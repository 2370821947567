import {Vue} from "vue-property-decorator";
import ConstantTool from "@/services/tool/ConstantTool";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import JsonTool from "@/services/tool/JsonTool";
import SnackbarModule from "@/store/SnackbarModule";
import Zone from "@/models/Zone";

export default class ZoneService {

    static async getZones(component: Vue, zones: Zone[]) {
        (<any>component).loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/zones", {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            let list = JsonTool.jsonConvert.deserializeArray(response.data, Zone)
            zones.splice(0, zones.length)
            list.forEach(v => zones.push(v));
            (<any>component).loading = false
        } catch(e) {
            getModule(SnackbarModule).makeToast("No se han podido obtener las zonas");
            (<any>component).loading = false
        }
    }
}