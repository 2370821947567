import {Vue} from "vue-class-component/dist/vue-class-component";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import SnackbarModule from "@/store/SnackbarModule";
import ConstantTool from "@/services/tool/ConstantTool";
import JsonTool from "@/services/tool/JsonTool";
import ProductExclusion from "@/models/ProductExclusion";

export default class ProductExclusionService {

    static async postProductExclusion(component: Vue, zoneId: number, brandId: number, productCategoryId: number) {
        // @ts-ignore
        component.loading = true

        let formData = new FormData()
        formData.set("zoneId", zoneId.toString())
        if (brandId) formData.set("brandId", brandId.toString())
        if (productCategoryId) formData.set("productCategoryId", productCategoryId.toString())

        try {
            await component.axios.post(ConstantTool.BASE_URL + "/api/product-exclusion", formData, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            // @ts-ignore
            component.loading = false
            // @ts-ignore
            component.refresh()
        } catch (err) {
            getModule(SnackbarModule).makeToast("Error al crear la exclusion");
            // @ts-ignore
            component.loading = false
        }
    }

    static async getProductExclusion(component: Vue, productExclusions: ProductExclusion[], page: number, size: number) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/product-exclusion", {
                params: {page, size},
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            let list = JsonTool.jsonConvert.deserializeArray(response.data, ProductExclusion)
            productExclusions.splice(0, productExclusions.length)
            list.forEach(p => productExclusions.push(p))
            // @ts-ignore
            component.totalItems = Number(response.headers["x-total-count"])
            // @ts-ignore
            component.loading = false
        } catch(err) {
            // @ts-ignore
            component.loading = false
            getModule(SnackbarModule).makeToast("No se han podido obtener las exclusiones")
        }
    }

    static async patchProductExclusion(component: Vue, id: Number, zoneId: number, brandId?: number, productCategoryId?: number) {
        // @ts-ignore
        component.loading = true

        let formData = new FormData()
        formData.set("zoneId", zoneId.toString())
        if (brandId) formData.set("brandId", brandId.toString())
        if (productCategoryId) formData.set("productCategoryId", productCategoryId.toString())

        try {
            await component.axios.patch(ConstantTool.BASE_URL + "/api/product-exclusion/" + id, formData, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            // @ts-ignore
            component.loading = false
            // @ts-ignore
            component.refresh()
            getModule(SnackbarModule).makeToast("Exclusión editada con exito.");
        } catch (err) {
            getModule(SnackbarModule).makeToast("Error al editar la exclusion.");
            // @ts-ignore
            component.loading = false
        }
    }


    static async deleteProductExclusion(component: Vue, id: number) {
        // @ts-ignore
        component.loading = true
        try {
            await component.axios.delete(ConstantTool.BASE_URL + "/api/product-exclusion/" + id, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            // @ts-ignore
            component.loading = false
            // @ts-ignore
            component.refresh()
            getModule(SnackbarModule).makeToast("La exclusion ha sido eliminado de manera exitosa!")
        } catch (err) {
            console.log(err)
            // @ts-ignore
            component.loading = false;
            getModule(SnackbarModule).makeToast("No se han eliminar la exclusion")
        }
    }

}