import {Vue} from "vue-property-decorator";
import ConstantTool from "@/services/tool/ConstantTool";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import JsonTool from "@/services/tool/JsonTool";
import SnackbarModule from "@/store/SnackbarModule";
import Brand from "@/models/Brand";
import axios from "axios";
import Response from "@/models/responses/Response";

export default class BrandService {

    static async getBrands(component: Vue, brands: Brand[]) {
        (<any>component).loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/public/brand", {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            let list = JsonTool.jsonConvert.deserializeArray(response.data, Brand)
            brands.splice(0, brands.length)
            list.forEach(v => brands.push(v));
            (<any>component).loading = false
        } catch(e) {
            getModule(SnackbarModule).makeToast("No se han podido obtener las marcas");
            (<any>component).loading = false
        }
    }

    static async getBrand(component: Vue, id: number) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/public/brand/" + id, {
                headers: { Authorization: getModule(SessionModule).session.token }
            });
            // @ts-ignore
            component.brand = JsonTool.jsonConvert.deserializeObject(response.data, Brand);
            // @ts-ignore
            component.loading = false;
        } catch (e) {
            getModule(SnackbarModule).makeToast("No se pudo obtener la marca")
        }
    }

    static async findBrands(page: number, size: number, search: string): Promise<Response<Brand[]>> {
        const response = await axios.get(ConstantTool.BASE_URL + "/api/brand", {
            params: { page, size, search },
            headers: { Authorization: getModule(SessionModule).session.token }
        })
        let brands = JsonTool.jsonConvert.deserializeArray(response.data, Brand)
        const xTotalCount = Number(response.headers["x-total-count"])
        return Promise.resolve({ result: brands, xTotalCount  })
    }

    static async synchronizeBrand() {
        try {
            const response = await axios.patch(ConstantTool.BASE_URL + "/api/synchronize/brand", null,{
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            if (response.status == 200) {
                getModule(SnackbarModule).makeToast("Las marcas se han empezado a sincronizar, este proceso podría tardar algunos minutos.")
            }
        } catch (e) {
            return Promise.reject(e)
        }
    }

}