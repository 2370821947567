import {JsonObject, JsonProperty} from "json2typescript";
import Brand from "@/models/Brand";
import ProductCategory from "@/models/ProductCategory";
import Zone from "@/models/Zone";

@JsonObject("ProductExclusion")
export default class ProductExclusion {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("brand", Brand, true)
    brand?: Brand = undefined
    @JsonProperty("productCategory", ProductCategory, true)
    productCategory?: ProductCategory = undefined
    @JsonProperty("zone", Zone, true)
    zone?: Zone = undefined

}
